import { formatPercent } from '@dextoroprotocol/sdk/utils'
import { wei, WeiSource } from '@synthetixio/wei'
import { FC, memo } from 'react'
import styled from 'styled-components'

import ChangeNegativeIcon from 'assets/svg/app/change-negative.svg'
import ChangePositiveIcon from 'assets/svg/app/change-positive.svg'
import { NO_VALUE } from 'constants/placeholder'

type ChangePercentProps = {
	value: WeiSource
	mono?: boolean
	fontSize?: number
	className?: string
	decimals?: number
	showArrow?: boolean
	showMinus?: boolean
}

export const ChangePercent: FC<ChangePercentProps> = memo(
	({ value, decimals = 2, showArrow = true, showMinus, ...rest }) => {
		const isValid = !!value
		const isZero = value && wei(value).eq(0)
		const isPositive = value && wei(value).gt(0)
		return (
			<CurrencyChange isValid={isValid} isPositive={isPositive} isZero={isZero} {...rest}>
				{!isValid || isZero ? (
					<>{NO_VALUE}</>
				) : !showArrow ? (
					<></>
				) : !isZero && isPositive ? (
					<ChangePositiveIcon />
				) : (
					<ChangeNegativeIcon />
				)}
				{showMinus && !isPositive && <span style={{ marginRight: 2 }}>-</span>}
				{!isZero && isValid && value && formatPercent(wei(value).abs(), { minDecimals: decimals })}
			</CurrencyChange>
		)
	}
)

const CurrencyChange = styled.span<{
	isValid: boolean
	mono?: boolean
	fontSize?: number
	isPositive: boolean
	isZero: boolean
}>`
	display: inline-flex;
	align-items: center;
	color: ${(props) =>
		!props.isValid || props.isZero
			? props.theme.colors.selectedTheme.white
			: props.isPositive
			? props.theme.colors.selectedTheme.green
			: props.theme.colors.selectedTheme.red};
	font-family: ${(props) => (props.mono ? props.theme.fonts.mono : props.theme.fonts.regular)};
	font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '13px')};
	font-feature-settings: ${(props) => (props.mono ? `'zero' 1` : `'zero' 0`)};
	height: 100%;

	svg {
		margin-right: 4px;
		width: 6px;
		height: 4px;
		path {
			fill: ${(props) =>
				props.isPositive
					? props.theme.colors.selectedTheme.green
					: props.theme.colors.selectedTheme.red};
		}
	}
`

export default ChangePercent
